export function useModelWrapper<T> (initialValue: T, emit: any, name = 'modelValue') {
  const scopedValue = reactive({ currentValue: initialValue })

  return computed({
    get: () => scopedValue.currentValue,
    set: (value) => {
      scopedValue.currentValue = value
      emit(`update:${name}`, value)
    },
  })
}
